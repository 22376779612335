/* Sidebar Styles */
.sidebarCollapsed {
  width: 80px !important;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.btn-collapsed-sidebar svg {
  color: #000000;
  width: minmax(16px, 16px);
  height: minmax(16px, 16px);
}
.sidebar {
  width: 208px;
  height: 100dvh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  position: sticky;
  top: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sidebar.sidebarCollapsed .sidebar__headerMenu {
  justify-content: center;
  padding: 10px 8px 16px 8px;
}

.sidebar__headerMenu {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 16px 16px;
  align-items: center;
}

.sidebar__headerMenu button:hover {
  color: #000095 !important;
  border-color: #000095 !important;
}

.sidebarCollapsed .list-btn.collapsed {
  width: 60px;
  color: #fff !important;
}
.active .list-btn svg {
  color: #fff !important;
}

.sidebar__headerMenu-Logo,
.sidebar__headerMenu-LogoCollapsed {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}

.sidebar__headerMenu-Logo {
  opacity: 1;
  width: 95px;
}

.sidebar__headerMenu-LogoCollapsed {
  opacity: 0;
  position: absolute;
  width: 0px;
}

.sidebar__menuList {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  flex: 1 !important;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-layout-sider-trigger {
  display: none !important;
  width: 0px !important;
}

.sidebar__menuList .ant-menu {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.sidebar__menuList .ant-menu-inline-collapsed {
  padding: 0 8px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar__menuList .ant-menu-inline-collapsed .ant-menu-submenu-title {
  margin: 4px 0 !important;
}

.sidebar__menuList .ant-menu-item-selected {
  background-color: #000095 !important;
  color: #fff !important;
}

.sidebar__footerMenu {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 8px;
}

.sidebar__footerMenu-info {
  display: flex;
  flex-direction: column;
}

.sidebar__footerMenu-info-name {
  font-weight: 500;
}

.sidebar__footerMenu-info-role {
  font-size: 13px;
  color: #64748b;
}
.sidebar__footerMenu-info-role::first-letter {
  text-transform: uppercase;
}

.sidebar .sidebar__menuList .menu-bar.ant-menu-inline-collapsed {
  width: 80px !important;
}

/* Main Content Styles */
.main {
  display: flex;
  gap: 16px;
  background-image: url("../../assets/images/bg-4.svg");
  background-size: cover;
  background-position-y: 90px;
  width: 100%;
}

.main .mainContent {
  flex-grow: 1;
  padding-right: 1.5rem;
}

.main .mainContent__assignments {
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
}

.main .mainContent__assignments-filter,
.main .mainContent__assignments-new {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main .mainContent__assignments-filter span,
.main .mainContent__assignments-new span {
  font-weight: 600;
}

.main .mainContent__assignments-filter button {
  padding: 4px 10px !important;
  box-shadow: 0px 0px 3.63px 0px #00000040;
}

.main .mainContent__assignments-filter button:hover {
  color: #000095 !important;
}

.main .mainContent__assignments-new input {
  width: 15rem;
  color: #5e5d5d;
}

.main .mainContent__assignments-new button {
  background-color: #000095;
  color: #fdd832;
}

.main .mainContent__assignments-new button:hover {
  background-color: #02027c !important;
  color: #fdd832 !important;
}
.tableBody__text {
  font-size: 13px;
  color: #919aab;
  &:hover {
    color: #000095;
  }
}
.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
}

.sidebar__footer-vertical,
.sidebar__footer-horizontal {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 18px 10px;
  border-top: 1px solid #e2e8f0;
  margin: 0 8px;
  justify-content: space-between;
}
.sidebar__footer-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.sidebar__footer-horizontal .logout {
  margin-top: 4px !important;
  padding: 0 !important;
}
.btn-logout {
  background-color: transparent;
  color: #ff0000;
  width: 21px;
  min-width: 21px;
  height: 21px;
  min-height: 21px;
  padding: 0 !important;
  border: none !important;
}
.btn-logout svg {
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
}

.sidebar-admin, .sidebar-super_admin {
  background-image: url("../../../public/assets/bg-admin-sidebar.svg");
}

.sidebar-teacher {
  background-image: url("../../../public/assets/bg-admin-teacher.svg");
}


.sidebar:where(.sidebar-admin, .sidebar-super_admin, .sidebar-teacher) .sidebar__footer-horizontal {
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.sidebar:where(.sidebar-admin.sidebarCollapsed,.sidebar-super_admin.sidebarCollapsed , .sidebar-teacher.sidebarCollapsed) .sidebar__footer-vertical {
  border: none;

  gap: 15px;
}
.sidebar:where(.sidebar-admin.sidebarCollapsed,.sidebar-super_admin.sidebarCollapsed , .sidebar-teacher.sidebarCollapsed) .btn-logout svg {
  color: #fff;
}
  .menu-language{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    gap: 10px;
  }

.menu-language .ant-btn{
  padding: 0 5px;
}

