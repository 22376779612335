body {
  box-sizing: border-box;
  background-color: #f8f9fd !important;
  min-width: 320px;
  min-height: 100vh;
  height: 100dvh;
  overflow-y: scroll;
  margin: 0 auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading{
  position: relative !important;
  height: 100vh !important;
  .loading__spin{
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    color: var(--main-color) !important;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-message{
  color: red;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
  margin-top: 3.7px;
}

:root {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  line-height: 1.5;
  font-weight: 400;
  overflow: hidden;
  background-color: #EFF2F5;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all ease .2S;
  --main-spacing: 16px;
  --main-color : #000095;
  --main-color-hover : #1818B1 ;
  --second-color : #FDD832;
  --second-color-hover : #EFC50E;
  --white-color : #fff;
  --black-color : #000;
  --text-color: #7D7D7D;
  --dim-gray: #4B4947;
  --shadow : 0px 0px 3.63px 0px #00000040;
  --main-radiuse : 8px;
  --btn-radius : 9.25px;
  --modal-radius : 27px;

  /*font size*/
  --font-size-xxs : 12px;
  --font-size-xs : 14px;
  --font-size-sm : 16px;
  --font-size-md : 18px;
  --font-size-lg : 20px;
  --font-size-xl : 22px;
  --font-size-xxl : 24px;
  --font-size-xxxl : 32px;
  --font-size-big-title : 32px;
  --font-size-xxxxl : 40px;
  --font-size-xxxxxl : 48px;
  --font-size-xxxxxxl : 56px;
  --font-size-xxxxxxxl : 64px;

  /*font weight*/
  --font-weight-regular : 400;
  --font-weight-medium : 500;
  --font-weight-semibold : 600;
  --font-weight-bold : 700;
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #FDD832;
}
::-webkit-scrollbar-thumb {
  background: #000095;
}