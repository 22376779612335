.flex,
.flex-reverse,
.flex-between,
.flex-col {
  display: flex;
}

.flex-col {
  flex-direction: column;
}
.flex-between {
  justify-content: space-between !important;
}
.flex-reverse{
  flex-direction: row-reverse !important;
}
.flex-y-center {
  display: flex;
  align-items: center;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: end;
}
.justify-end {
  justify-content: end;
}
.flex-grow-1 {
  flex-grow: 1;
}

.grid,
.grid-cols-2,
.grid-cols-3,
.grid-cols-4 {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.gap-4 {
  gap: 4px !important;
}
.gap-8 {
  gap: 8px !important;
}
.gap-10 {
  gap: 10px !important;
}
.gap-12 {
  gap: 12px !important;
}
.gap-14 {
  gap: 14px !important;
}
.gap-16 {
  gap: 16px !important;
}
.gap-20 {
  gap: 20px !important;
}
.gap-24 {
  gap: 24px;
}
.gap-32 {
  gap: 32px;
}

.p-2{
  padding: 2px;
}
.py-2{
  padding-top: 2px;
  padding-bottom: 2px;
}
.px-2{
  padding-left: 2px;
  padding-right: 2px;
}
.py-4{
  padding-top: 4px;
  padding-bottom: 4px;
}
.px-4{
  padding-left: 4px;
  padding-right: 4px;
}
.py-6{
  padding-top: 6px;
  padding-bottom: 6px;
}
.px-6{
  padding-left: 6px;
  padding-right: 6px;
}
.py-8{
  padding-top: 8px;
  padding-bottom: 8px;
}
.px-8{
  padding-left: 8px;
  padding-right: 8px;
}
.py-10{
  padding-top: 10px;
  padding-bottom: 10px;
}
.px-10{
  padding-left: 10px;
  padding-right: 10px;
}
.py-12{
  padding-top: 12px;
  padding-bottom: 12px;
}
.px-12{
  padding-left: 12px;
  padding-right: 12px;
}
.py-14{
  padding-top: 14px;
  padding-bottom: 14px;
}
.px-14{
  padding-left: 14px;
  padding-right: 14px;
}
.py-16{
  padding-top: 16px;
  padding-bottom: 16px;
}
.px-16{
  padding-left: 16px;
  padding-right: 16px;
} 
.py-18{
  padding-top: 18px;
  padding-bottom: 18px;
}
.px-18{
  padding-left: 18px;
  padding-right: 18px;
}
.py-20{
  padding-top: 20px;
  padding-bottom: 20px;
}
.px-20{
  padding-left: 20px;
  padding-right: 20px;
}
.py-24{
  padding-top: 24px;
  padding-bottom: 24px;
}
.px-24{
  padding-left: 24px;
  padding-right: 24px;
}


.h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}
.h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}
.h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.text-14,
.text-16,
.text-18 {
  font-family: "Inter";
  font-style: normal;
}
.text-14 {
  font-size: 14px;
  line-height: 20px;
}
.text-16 {
  font-size: 16px;
  line-height: 24px;
}
.text-18 {
  font-size: 18px;
  line-height: 24px;
}

.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 600;
}

.black {
  color: #000;
}
.white {
  color: #fff;
}

.h-screen{
height: 100vh;
position: relative;
}
.loader-full-screen{
  height: min-content;
  >div{
    top: 50vh;
    transform: translateY(-50%);
  }
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.w-full{
    width: 100%;
}

.sendButton {
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 6px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background: #f1f5f9;
    color: #64748B;
    cursor: not-allowed;
  }
}