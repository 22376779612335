.accordion{
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}
.accordion:first-child{
    margin-bottom: 20px;
}

.accordion__title,
.accordion__title-student {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11.5px 12px;
    border-top-left-radius: var(--main-radiuse);
    border-top-right-radius: var(--main-radiuse);
    cursor: pointer;
}

.accordion__title{
    background-color: rgba(255, 207, 0, 1);
}
.accordion__title-student{
    background: rgba(0, 0, 149, 1);
}

.accordion__title h1,
.accordion__title-student h1 {
    font-size: var(--font-size-md);
    font-weight: 600;
    line-height: var(--line-height-md);
    color: #fff;
}

.accordion__title {
    h1 {
        color: var(--main-color) !important;
    }
    .arrow {
        color: var(--main-color) !important;
    }
}

.accordion__title h1{
    -webkit-line-clamp: 1;
    width: 50%;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.accordion__content {
    padding: 0 14.6px;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.4s ease, padding 0.5s ease;
    max-height: 0;
    opacity: 0;
}

.accordion__content.toggled {
    max-height: 1000px;
    padding: 11.5px 14.6px 21.5px 14.6px;
    opacity: 1;
}

@media (max-width:1024px) {
    .accordion__content{
        grid-template-columns: 1fr;
    }
}

.accordion__content h1{
    font-size: var(--font-size-xl);
    font-weight: 600;
    line-height: var(--line-height-xl);
    text-align: left;
}

.accordion__content img{
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    object-fit: contain;
}

.accordion__content p{
    font-size: var(--font-size-md);
    font-weight: 400;
    line-height: var(--line-height-md);
    text-align: left;
    color: rgba(65, 64, 66, 1);
    padding-right: 30px;
}

.accordion__content-student {
    padding: 0 10px;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.4s ease, padding 0.5s ease;
    max-height: 0;
    opacity: 0;
}
.accordion__content-student.toggled {
    max-height: 500px;
    padding: 19px 10px;
    opacity: 1;
}

.accordion__actions__button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.accordion__navigation_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.upload__actions{
    display: flex;
}

.accordion__content-student pre{
    font-size: var(--font-size-md);
    font-weight: 400;
    line-height: var(--line-height-md);
    text-align: left;
    color: rgba(51, 51, 51, 1);
    white-space: pre-wrap;
    word-break: break-word;
}

.accordion__upload-btn{
    padding: 19px 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--main-color);
    color: #fff;
    margin-right: 6px;
}
.slate:focus-visible{
    outline: none;
}

.accordion__upload-btn:hover{
    background-color: var(--main-color-hover) !important;
    color: #fff !important;
}
.accordion__upload-btn input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 38px;
    height: 38px;
}

.arrow{
    color: #fff;
    font-size: 18px;
    transform: rotate(-90deg);
    transition: all ease .4s;
}
.arrow.toggled{
    transform: rotate(90deg);
}
.btn-correction{
    background-color: var(--main-color) !important;
    width: 10%;
    margin-top: 10px !important;
}

.figure-coursework{
    max-width: 150px;
}

.teacher__assignment{
    .accordion__content.toggled{
        max-height: 390px;
        padding: 29px 20px;
        opacity: 1;
        overflow-y: auto;
    }
    .instruction__content{
        max-height: 390px;
    }
}

.assignmentInfos{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 28.81px;
    margin-bottom: 14px;
}

/* style pour les ressources */

.resourcesConteiner{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 14px;
}

.resourcesConteiner h6{
    font-size: var(--font-size-md);
    font-weight: 600;
    color: #344054;
    padding-top: 20px;
}

.resourceItemContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}

.resourceItem, .resourceItem:hover{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    border: 1px solid #EAECF0;
    background: #ffffff;
    border-radius: var(--main-radiuse);
    padding: 6px 14px;
    cursor: pointer;
    color: #344054;
    height: 51px;
    font-size: var(--main-color);
}

.resourceItem :global(.anticon.anticon-file){
    background-color: #A6C4FB;
    border: 4px solid #DCE8FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.resourceItem :global(.anticon.anticon-global){
    background-color: #AC8DC3;
    border: 4px solid #D5C5E1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    color: #ffffff;
}

.resourceItem :global(.anticon.anticon-youtube){
    background-color: #AC8DC3;
    border: 4px solid #D5C5E1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.videoContainer{
    align-items: center;
    background-color: #f9fafb;
    border-radius: var(--main-radiuse);
    box-shadow: 0 1px 6.8px 0 #00000040;
    display: inline-flex;
    flex-direction: row;
    height: 270px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.videoContainer iframe{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(312.86deg, rgba(255, 207, 0, 0.63) 17.63%, rgba(255, 216, 115, 0.63) 40.19%, rgba(56, 64, 255, 0.63) 77.73%, rgba(0, 0, 149, 0.63) 104.04%);
    border-radius: var(--main-radiuse);
    margin: 12px;
    height: 92%;
}

.videoContainer img {
    width: 100%;
    display: block; /* Prevents unwanted space below image */
    background-color: #fff;
    border-radius: var(--main-radiuse);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, .25));
    height: 270px;
    object-fit: cover;
    overflow: hidden;
    padding: 12px;
    width: 100%;
}
  
.playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem; /* Adjust size if necessary */
    color: white; /* Adjust based on your design */
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}
  
.playIcon:hover {
    transform: translate(-50%, -50%) scale(1.1);
}

.resourceRow{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.resourceColomn{
    display: flex;
    flex-direction: column
}

.rightSideBarPreview__open{
    width: 60%;
}

.toolbar{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.color-gray{
    color: var(--dim-gray);
}

.fileContainer{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
}

.fileBtn, .fileBtn:hover{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: var(--second-color) !important;
    color: var(--main-color) !important;
    font-size: var(--font-size-md);
    border: none !important;
    border-radius: 6px;
    padding: 5px;
    cursor: pointer;
    height: 40px;
}

.fileInputWordPdf{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 60px;
    height: 38px;
    cursor: pointer;
}

.fileInputHandwriting{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 80px;
    height: 38px;
    cursor: pointer;
}

.showMoreButton {
    background-color: #E0E7FF;
    color: var(--main-color);
}

.showMoreButton svg{
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg); /*opera*/
    transform: rotate(180deg); /*likely future standard*/
}

.context{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: var(--main-color);
}

.contextTitle{
    font-size: var(--font-size-md);
    font-weight: 700;
}

.textContent{
    font-size: var(--font-size-md);
    font-weight: 500;
}