@import "../auth.css";

.register__subTitle {
  font-size: 18.81px;
}

.register__subTitle a {
  color: var(--main-color) !important;
}

.register__subTitle a:hover {
  text-decoration: underline;
}

.register__form-fullName {
  margin-bottom: var(--main-spacing);
  display: flex;
  gap: 8px;
}

.register__form-fullName input,
.register__form-email,
.register__form-password,
.register__form-confirmPassword{
  border: none;
  box-shadow: 0px 0px 9.25px 0px rgba(0, 0, 0, 0.05);
}

.register__form-fullName input::placeholder {
  color: var(--text-color);
}

.register__form-email::placeholder {
  color: var(--text-color);
}

.register__form-emailField,
.register__form-passwordField,
.register__form-confirmPasswordField,.register__form-class {
  margin-bottom: var(--main-spacing);
}

.register__form-password input::placeholder,
.register__form-confirmPassword input::placeholder {
  color: var(--text-color);
}

.register__form-checkbox {
  color: var(--text-color);
  font-size: 17px;
  font-weight: 400;
}

.register__form-checkbox a {
  color: var(--main-color) !important;
}

.register__form-checkbox a:hover {
  text-decoration: underline;
}

.register__form-checkbox .ant-checkbox {
  align-self: self-start;
  margin-top: 9px;
}

.register__form-checkbox .ant-checkbox-inner {
  border-radius: 0px !important;
}

.register__form-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}

.register__form-btn {
  margin-top: 18px;
  width: 163px !important;

}

:where(.register__form-classField, .register__form-emailField) input:disabled {
  border: none !important;
}
