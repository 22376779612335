.rightSideBarPreview {
    width: 0;
    max-width: 36%;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background-color: #F8F9FD;
    z-index: 100;
    overflow-y: scroll;
    border-left: 1px solid #00009536;
    box-shadow: 0px 0px 4px 0px #00000040;
    transition: width 0.3s ease-in-out, box-shadow 0.3s ease, transform 0.3s ease-in-out;
    overflow-x: hidden;
    transform: translateX(100%); /* Start offscreen */
}

.rightSideBarPreview__open {
    width: 36%;
    transform: translateX(0); /* Slide in */
}

.rightSideBarPreview__closing {
    width: 0;
    transform: translateX(100%); /* Slide out */
}

.rightSideBarPreview__content {
    margin-bottom: 6px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.1s;
}

.rightSideBarPreview__open .rightSideBarPreview__content {
    opacity: 1;
}



.rightSideBarPreview__content{
    margin-bottom: 6px;
    /* Make the content opacity animate as well */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    /* Add a slight delay to content appearance */
    transition-delay: 0.1s;
}

.closeIcon{
    cursor: pointer;
    height: 20px;
    vertical-align: middle;
    /* Add transition for hover effect */
    transition: transform 0.2s ease;
}

.closeIcon:hover {
    transform: scale(1.1);
}

.rightSideBarPreview__open{
    width: 36%;
}



@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.rightSideBarPreview__open {
    animation: slideIn 0.3s ease-in-out;
}

.rightSideBarPreview:not(.rightSideBarPreview__open) {
    animation: slideOut 0.3s ease-in-out;
}

.rightSideBarPreview__content :global(.ant-tabs-nav){
    padding: 20px 15px 0px 15px;
}

.rightSideBarPreview__content :global(.ant-tabs-top >.ant-tabs-nav){
    box-shadow: 0px 3px 6.3px 0.82px #0000000D;
}

.rightSideBarPreview__content :global(.ant-tabs-top >.ant-tabs-nav::before){
    border-bottom: none;
}

.rightSideBarPreview__content :global(.ant-tabs-tab-btn){
    display: flex;
    align-items: center;
    gap: 10px;
}

.rightSideBarPreview__content :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn){
    color: #667085;
    font-size: var(--font-size-md);
}

.rightSideBarPreview__content :global(.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn){
    color: #000095;
}

.rightSideBarPreview__content :global(.ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn .ant-tabs-tab-icon){
    background: #626A7D;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 3px 7px;
    margin-inline-end: 0px;
    color: #fff;
}

.rightSideBarPreview__content :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .ant-tabs-tab-icon){
    background: linear-gradient(131.76deg, #000095 8.5%, #2E1493 33.96%, #562691 58.73%, #662D91 71.8%);
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 3px 7px;
    margin-inline-end: 0px;
    color: #fff;
}

.rightSideBarPreview__content :global(.ant-tabs-tab .ant-tabs-tab-btn .ant-tabs-tab-icon svg){
    height: 16px;
    width: 16px
}

.rightSideBarPreview__content :global(.ant-tabs .ant-tabs-ink-bar){
    background: #000095;
    /* Add transition for the ink bar */
    transition: width 0.3s ease, left 0.3s ease;
}


.rightSideBarPreview__content :global(.ant-tabs-tab) .selectRightSideBar :global(.ant-select-selector){
    background-color: #626A7D !important;
    border-radius: 17px;
    color: #ffffff !important;
    transition: background-color 0.3s ease;
}

.rightSideBarPreview__content :global(.ant-tabs-tab-active) .selectRightSideBar :global(.ant-select-selector){
    background-color: var(--main-color) !important;
    border-radius: 17px;
    color: #ffffff !important;
}

.selectRightSideBar :global(.ant-select-arrow){
    color: #ffffff !important;
}

.remediaIcon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    /* Add a subtle transition for icon changes */
    transition: transform 0.2s ease;
}

/* Add hover effect for the remedia icon */
.remediaIcon:hover {
    transform: scale(1.1);
}