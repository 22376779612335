.button {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: max-content;
  height: fit-content;
  gap: 8px;
}

.ghost{
  background: transparent;
  color: #000;
  border: unset;
  box-shadow: unset;
  border-radius: unset;
}

.primary {
  background: #000095;
  color: #fff;
  border: 1px solid #000095;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.default {
  background: #ffffff;
  color: #344054;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
.yellow {
  background: #ffcf00;
  color: #000095;
  border: 1px solid #ffcf00;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 8px;
}
.sm {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  max-height: 40px;
}
.md {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  max-height: 44px;
}
.block {
  width: 100%;
}
