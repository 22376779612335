aside{
    background-color: transparent !important;
}
.list{
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 9px 0  8px;
    margin-top: 1rem;
}

.list .active .list-btn{
    background-color: var(--main-color);
    color: #fff;
    border: none !important;
    box-shadow: none;

}

.list-btn{
    border: none;
    outline: none;
    background-color: transparent;
    color: #262626;
    display: flex;
    align-items: center;
    gap: 11px;
    width: 100%;
    padding: 10px 11px;
    border-radius: 8px;
    font-size: var(--font-size-md);
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    transition: all ease-out .2s;
}

.list-btn.collapsed{
    width: 40%;
    justify-content: center;
}

.list-btn.collapsed .icon{
    font-size: 18px !important;
}

.list-btn:hover{
    background-color: #F7F9FC;
}

.classroom-name, .organization-name{
  background-color: var(--second-color) ;
  color: var(--main-color);
  border: none !important;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2px;
  width: 100%;
  padding: 10px 11px;
  border-radius: 8px;
  font-size: var(--font-size-md);
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition: all ease-out .2s;

}
.classroom-name-btn{

}
.organization-name{
  background-color: var(--main-color) !important;
  color: #fff !important;
}
.classroom-name button, .organization-name button{
  display: flex;
  align-items: center;
  justify-content: center;
}
.organization-name button svg{
  color: #fff !important;
}
.classroom-name button svg{
  color: var(--main-color);
}
.classroom-name.collapsed{
    width: 40%;
    justify-content: center;
}

.sidebar.sidebarCollapsed + main .container-help-assistant {
    min-width: calc(100% - 120px); 
  }
  .sidebar.sidebarCollapsed + main .content-help-assistant{
    min-width: calc(100% - 120px);
  }
  .sidebar.sidebarCollapsed + main  .mask-help-assistant{
    width: calc(100% - 80px);
  }

.submenu-container {
  width: 100%;
  padding: 0;
}

.submenu-container > .list-btn {
  color: white;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.submenu-container > .list-btn:hover {
  background: #283593;
}

.submenu {
  padding: 0 0 0 12px;
}

.submenu .list-btn {
  position: relative;
  padding: 0 0 0 18px;
  margin: 4px 0;
  width: calc(100% - 24px);
  border-radius: 4px;
}

.submenu .list-btn::before {
  content: '';
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 14px;
  border-radius: 2px;
}

.submenu-item {
  font-size: 15px;
  color: #424242;
  transition: all 0.3s ease;
}
.submenu-container  .list-btn span{
  font-size: 13px ;
}
.submenu-container .active .list-btn{
  background-color: transparent ;
  color: var(--main-color);
}
.submenu-container .active .list-btn::before{
  display: block;
  background-color: var(--main-color);
}
.submenu-item:hover {
  background-color: #f5f5f5;
}

.submenu .list-btn .anticon {
  margin-right: 12px;
  font-size: 16px;
}

.list-btn .anticon-caret-down,
.list-btn .anticon-caret-up {
  margin-left: auto;
  font-size: 12px;
}