.class-modal .modal-header {
  margin-bottom: 24px;
}

.class-modal .modal-header h1 {
  font-size: 24px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 8px;
}

.class-modal .modal-header h2 {
  font-size: 14px;
  font-weight: 400;
  color: #475467;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}



/* Ant Design overrides */
.class-modal .ant-input,
.class-modal .ant-select {
  width: 100%;
}

.class-modal .ant-input {
  padding: 10px 14px;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

