:root {
    --main-spacing: 10px;
    --text-color: #7D7D7D;
}

.container {
    width: 700px;
    height: 100dvh;
    margin: auto;
    display: flex;
    align-items: center;
}

.passwordRecovery {
    background-color: #fff;
    color: #4B4947;
    width: 390px;
    min-width: 600px;
    margin: 0 auto;
    border-radius: 30px;
    padding: 4.5rem 4rem;

}

.passwordRecovery__title {
    font-size: 30px;
    margin-bottom: var(--main-spacing);
    text-align: center;
}

.passwordRecovery__subTitle {
    color: var(--text-color);
    font-size: 15px;
    text-align: center !important;
}

.passwordRecovery__form {
    margin-top: calc(var(--main-spacing) + 10px);
    margin-bottom: calc(var(--main-spacing) + 5px);
}

.passwordRecovery__form-email {
    margin-bottom: var(--main-spacing);
}

.passwordRecovery__form-email::placeholder {
    color: var(--text-color);
}

.passwordRecovery__form-btn {
    font-weight: 600;
    background-color: #000095;
    color: #FDD832;
}

.passwordRecovery__form-btn:hover {
    background-color: #02027c !important;
    color: #FDD832 !important;
}

.passwordRecovery__backToLogin {
    font-weight: 600;
    color: #000095;
    display: flex;
    justify-content: center;
    gap: 6px;
}

.passwordRecovery__backToLogin:hover {
    color: #02027c !important;
}
