.modal {
  display: flex;
  justify-content: space-between;
  .modalHeader {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    min-height: 48px;
    min-width: 48px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
    > svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }
  .close {
    cursor: pointer;
    width: 44px;
    height: 44px;
    background: #ffffff;
    color: #667085;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: #101828;
    }
    > svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }
}

.modalContent {
  padding: 16px 0 32px 0;
}
.modalFooter {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
