.admin-teachers,
.admin-students {
  overflow-y: auto;
  margin-top: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 6.1px rgba(0, 0, 0, 0.17);
  border-radius: 14px;
  max-height: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 32px 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .teachers-container,
  .students-container {
    display: flex;
    flex-direction: column;
    .section {
      display: flex;
      gap: 16px;
      padding: 24px 0 16px 0;
      border-bottom: 1px solid #E4E7EC;
      &:last-child {
        border-bottom: none;
      }
      .left-side {
        max-width: 280px;
        min-width: 280px;
        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #475467;
        }
        > .image-excel {
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .right-side {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .btn-delete {
          color: #475467;
        }
      }
      .select-classes {
        min-width: 250px;
        &-max {
          max-width: 250px;
        }
      }
    }
  }

  .record-teacher {
    display: flex;
    gap: 10px;
    svg {
      border-radius: 50%;
      min-width: 40px;
      min-height: 40px;
      object-fit: contain;
      object-position: center;
    }
    .record-teacher-info {
      display: flex;
      flex-direction: column;
      .table-name {
        color: #101828;
      }
      .table-email {
        color: #475467;
      }
    }
  }

  .tbody-mx-600 {
    position: relative;
    
    table {
      width: 100%;
    }
    
    thead {
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
    }
    
    tbody {
      display: block;
      max-height: 600px;
      overflow-y: auto;
    }
    
    thead, tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}
