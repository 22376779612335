.timerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    color: var(--second-color);
    font-size: 22px;
    padding: 4px 8px;
    max-width: 300px;
    width: 100px;
    border-radius: var(--main-radiuse);
  }
  
  .timerText {
    font-weight: bold;
  }
  