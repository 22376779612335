.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  display: flex;
  gap: 4px;
}

.required {
  color: #f04438;
}

.custom-select {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  min-height: 40px !important;
  line-height: 40px !important;
  &.default {
    height: 40px;
  }
}

.custom-select:hover {
  border-color: #84caff;
}

.custom-select.error {
  border-color: #fda29b !important;
}

.custom-select.error:hover {
  border-color: #f04438 !important;
}

.custom-select :global(.ant-select-selector) {
  padding: 6px 14px !important;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  min-height: 40px !important;
  &.default {

    height: 40px !important;
  }
}

.custom-select :global(.ant-select-selection-item) {
  padding: 0 12px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
}

.custom-select :global(.ant-select-arrow) {
  color: #667085;
}

.custom-select :global(.ant-select-selection-item-content) {
  color: #344054;
  font-weight: 500;
}

.custom-select-popup {
  background-color: #ffffff;
  border-radius: 8px;
}

.custom-select-popup :global(.ant-select-item) {
  padding: 8px 14px;
  font-family: "Inter", sans-serif !important;
  font-size: 14px;
  color: #344054 !important;
    font-weight: 500 !important;

}
.custom-select-popup :global(.anticon-check) {
  color: #000095;
}

.custom-select-popup :global(.ant-select-item-option-selected) {
  background-color: #f9fafb !important;
}

.custom-select-popup :global(.ant-select-item-option-active) {
  background-color: #f9fafb;
}

.errorMessage {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #f04438;
}

.custom-select :global(.ant-select-selection-item) {
  padding: 2px 4px 2px 5px;
  background: #ffffff !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 6px;
}


.default :global(.ant-select-selection-item) {
  padding: 2px 4px 2px 5px;
  background: #ffffff !important;
  border: none !important;
}