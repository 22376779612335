h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
.header{
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0px 18px 10px !important; 
    margin: 0px -20px 6px !important;
}
.helpAssistantButton{
    background-image: url('../../../public/assets/images/btn-avatar.png');
    background-size: cover;
    background-position: center;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}
.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.4px;
    color: #1C1D1E;
}
.containerPreviewContent {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    padding: 20px;
}

.rightSideBarPreview__open{
    width: 60%;
}

.studentInfoPagination{
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0px 1px 4px 0px #00000040;
    background-color: #ffffff;
    border-radius: var(--main-radiuse);
    height: 40px;
}

.studentInfo label{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--font-size-md);
    font-weight: 600;
}

.introductionTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px 28px;
    color: #101828;
    padding-bottom: 6px;
}
.introduction {
    font-weight: 400;
    font-size: var(--font-size-md);
    line-height: 20px;
    color: #475467;
    padding-bottom: 15px;
}
.listQuistionsContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.questionItemContant {
    padding: 1px;
    border-radius: 8px;
}
.questionItemContant > .header {
    padding: 8px 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
}
.titleQuistion {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.titleQuistion > h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
}
.badgeType {
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    color: #344054;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    background: #F2F4F7;
    border-radius: 16px;
}
.header button {
    border: none;
    background: none;
    cursor: pointer;
    color: #344054;
}
.contentMain {
    box-sizing: border-box;
    padding: 16px;
    background: #F8F9FD;
    border: 1.02904px solid #FFCF00;
    box-shadow: 0px 2px 8.9px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 8px 8px;
    height: fit-content;
    transition: transform 0.1s ease-in-out;
    transform-origin: top;
    overflow: hidden; /* Ensures content doesn't overflow during animation */
}
.hide {
    height: 0;
    opacity: 0;
    transform: scaleY(0); /* Adds a shrinking effect */
    position: absolute;
}
.bodyQuestion {
    display: flex;
    gap: 13px;
}
.bodyQuestion img {
    width: 187px;
    height: 187px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    border-radius: 7.68222px;
}
.textContent {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}
.options {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 42px;
}
.optionItem {
    width: fit-content;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 17px;
    padding-right: 17px;
    gap: 6px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}
.optionItem.selected {
    background: #FFCF00;
    border: 1px solid #000095;
    border-radius: 8px;
}
.checkboxContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    user-select: none;
    padding-right: 17px;
    padding-left: 17px;
    gap: 6px;
    height: 47px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    transition: background-color 0.3s, border-color 0.3s;
}
.checkedOption {
    background: #FFCF00;
    border: 1px solid #000095;
}
.customCheckbox {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    transition: background-color 0.3s, border-color 0.3s;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 6px;
}
.customCheckbox.checked {
    border: 1px solid #000095;
    background-color: #D4D4FB;
}
.checkmark {
    width: 14px;
    height: 14px;
}
.checkboxLabel {
    font-size: 16px;
    color: #000095;
}
.inputAnswer {
    padding: 13px 17px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 131%;
    color: #333333;
    width: 100%;
    outline: none;
    border: none;
}
.textareaLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}
.textareaV2 {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 131%;
    color: #333333;
    outline: none;
    border: none;
    resize: none;
}
.textarea {
    box-sizing: border-box;
    padding: 10px 14px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    transition: all 0.3s ease;
    border-radius: 8px;
    resize: none;
    outline: none;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
}
.justification:focus,
.textareaV2:focus,
.justification:focus-within,
.textareaV2:focus-within {
    border: 1px solid #000095;
}
.textareaContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.btnSubmitContainer {
    display: flex;
    justify-content: flex-end;
}
.btnSubmit {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #000095;
    border: 1px solid #000095;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
}
.backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    background: #FFFFFF !important;
    border: 1px solid #EAECF0 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
}

.courseworkHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.rubricButtonContainer{
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    align-items: center;
}

.rubricButton{
    color: #000095;
    background-color: var(--second-color);
    border: 1px solid #000095;
    border-radius: 8px;
    padding: 5px 8px;
    cursor: pointer;
}

.timerContainer{
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 8px;
}

.container{
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  .header{
    display: flex;
    align-items: center;
    gap: 16px;
  }
}