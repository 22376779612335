.container {
  background: #ffffff;
  box-shadow: 0px 0px 6.1px rgba(0, 0, 0, 0.17);
  border-radius: 14px;
  padding: 32px 10px;
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #0c111d;
}
.main {
  height:calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  max-width: 355px;
}

.content img {
  width: 100%;
}
.title {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 30px;
text-align: center;
color: #101828;
}

.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #475467;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
