

/* Main Content Styles */

.content__assignments {
  flex-grow: 1;
  padding-right: 1.5rem;
}

.content__assignments .assignment__top-page {
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  margin-bottom: 1rem;
}

.assignment-tabs .ant-tabs-nav-list{
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px; */
  border-top-left-radius: var(--main-radiuse) !important;
  border-top-right-radius: var(--main-radiuse) !important;
  background-color: #ffffff;
}

.assignment-tabs .ant-tabs-nav-list .ant-tabs-tab{
  padding: 12px 12px !important;
}
.assignment-tabs .ant-tabs-nav-list .ant-tabs-tab:hover{
  color: #000095 !important;
}
.assignment-tabs .ant-tabs-nav-list .ant-tabs-tab-btn{
   font-size: var(--font-size-lg) !important;
   font-weight: 600 !important;
   line-height: var(--font-size-lg) !important;
   display: flex;
   align-items: center;
   gap: 5px;
   color: #667085;
}

.assignment-tabs .ant-tabs-nav-list .ant-tabs-tab-btn:hover{
  color: #000095 !important;
}

.assignment-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #000095 !important;
}
.assignment-tabs .ant-tabs-ink-bar{
  background-color: #000095 !important;
}

.assignment-tabs>.ant-tabs-nav::before{
  border-bottom: none !important;
}
.assignment-tabs .ant-tabs-nav-list .ant-tabs-tab span.tab-mode-icon-container {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
}

.assignment-tabs .ant-tabs-tab.ant-tabs-tab-active span.tab-mode-icon-container, .assignment-tabs .ant-tabs-nav-list .ant-tabs-tab:hover span.tab-mode-icon-container{
  background: linear-gradient(131.76deg, #000095 8.5%, #2E1493 33.96%, #562691 58.73%, #662D91 71.8%);
  color: #ffffff;
}

.main-content .assignments__filter,
.main-content .assignments-new {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-content .assignments__filter span,
.main-content .assignments-new span {
  font-weight: 600;
}

.main-content .assignments__filter h1{
  font-size: var(--font-size-xxl) !important;
  line-height: var(--font-size-xxl) !important;
  font-weight: 600 !important;
}

.main-content .assignments__filter button {
  padding: 4px 10px !important;
  box-shadow: 0px 0px 3.63px 0px #00000040;
  font-size: var(--font-size-md) !important;
  line-height: var(--font-size-md) !important;
  font-weight: 600 !important;
  border-radius: var(--main-radiuse) !important;
  height: 40px;
}

.main-content .assignments__filter button span{
  font-size: var(--font-size-md) !important;
  line-height: var(--font-size-md) !important;
  font-weight: 600 !important;
}

.main-content .assignments__filter button:hover {
  color: #000095 !important;
}

.main-content .assignments-new input {
  width: 15rem;
  color: #5e5d5d;
  height: 30px;
}

.main-content .assignments-new button {
  background-color: #000095;
  color: #FDD832;
  height: 40px;
}

.main-content .assignments-new button:hover {
  background-color: #02027c !important;
  color: #FDD832 !important;
}
.tableBody__text {
  font-size: 12px;
  line-height: 15px;
  color: #919AAB;
  &:hover {
    color: #000095;
  }
}

.main-content .content__assignments .assignment__top-page .assignments__filter span {
  font-size: var(--font-size-xxl);
  line-height: 24px;

}

.main-content .content__assignments .assignment__top-page .assignments-new button{
  font-size: var(--font-size-sm);
  line-height: 20px;
  text-align: center;
}

.sort_column{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  cursor: pointer;
  .sort__icons{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #a5a5b4;
    font-size: 10px;
  }
  .up__icon.active{
    color: #000095;
  }
  .down__icon.active{
    color: #000095;
  }
}

button.import__assignment__dropdown:hover, button.import__assignment__dropdown:focus  {
    color: var(--main-color) !important;
    border-color: var(--main-color) !important;
}