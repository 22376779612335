.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .label {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
  }

  .required{
    color:#344054 ;
  }
  .container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &.error {
      .field {
        border-color: #ef4444;
      }
    }

    .prefix,
    .suffix {
      position: absolute;
      display: flex;
      align-items: center;
      color: #64748b;
      height: 100%;
      padding: 0 12px;
      pointer-events: none;
    }

    .prefix {
      left: 0;
    }

    .suffix {
      right: 0;
    }

    .field {
      width: 100%;
      padding: 10px 16px;
      font-size: 14px;
      outline: none;
      transition: all 0.2s ease-in-out;

      // Remove arrows for number input
      &[type="number"] {
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &.withPrefix {
        padding-left: 40px;
      }

      &.withSuffix {
        padding-right: 40px;
      }

      &:focus {
        border-color: #3b82f6;
        box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
      }
    }
  }

  .error {
    font-size: 12px;
    color: #ef4444;
    margin-top: -4px;
  }

  .hint {
    font-size: 12px;
    color: #64748b;
  }

  // Size variants
  &.sm {
    .field {
      padding: 8px 12px;
      font-size: 13px;
    }

    .prefix,
    .suffix {
      padding: 0 8px;
    }

    .field.withPrefix {
      padding-left: 32px;
    }

    .field.withSuffix {
      padding-right: 32px;
    }
  }

  &.md {
    .field {
      padding: 8.2px 16px;
      font-size: 14px;
    }
  }

  &.lg {
    .field {
      padding: 12px 20px;
      font-size: 17px;
    }

    .prefix,
    .suffix {
      padding: 0 16px;
    }

    .field.withPrefix {
      padding-left: 48px;
    }

    .field.withSuffix {
      padding-right: 48px;
    }
  }

  &.primary {
    .field {
      background-color: #f8fafc;
    }
  }

  &.default {
    .field {
      background-color: white;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #101828;
    }
  }
}
