.tagInputContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tagInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #ccc;
  padding: 3px 14px;
  border-radius: 5px;
  min-height: 40px;
  box-sizing: border-box;
  row-gap: 4px;
  column-gap: 6px;

  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.error {
  border-color: red;
}

.tag {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px 2px 5px;
  gap: 3px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  color: #344054;
}


.userIcon {
  width: 16px;
  height: 16px;
}

.tag button {
  transition: all 0.3s ease-in-out;
  svg{
    width: 12px;
    height: 12px;  
  }
  &:hover {
    color: #344054;
  }
}

input {
  border: none;
  outline: none;
  padding: 5px;
  flex-grow: 1;
}

.errorText {
  color: red;
  font-size: 12px;
}
