.budge {
  width: fit-content;
  height: fit-content;
  font-family: "Inter";
  font-weight: 500;
  &.xs {
    font-size: 12px;
    line-height: 18px;
    padding: 0 8px;
  }
  &.sm {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;
  }
  &.md {
    font-size: 14px;
    line-height: 18px;
    padding: 4px 8px;
  }
  &.lg {
    font-size: 16px;
    line-height: 20px;
    padding: 6px 12px;
  }
  &.active {
    background: #ecfdf3;
    border-radius: 16px;
    color: #027a48;
  }
  &.pending {
    background: #fffaeb;
    border-radius: 16px;
    color: #b54708;
  }
  &.primary-rounded {
    background: #000095;
    border-radius: 16px;
    color: #ffcf00;
  }
  &.primary {
    background: #000095;
    color: #ffcf00;
    border-radius: 3.62879px;
  }
}
