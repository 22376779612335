@use "reset";
@use "base";
@use "utilities";
@use "layouts";
@use "modules/teachers";



@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
//  font-family: "Work Sans", serif;
//  font-family: "Inter", serif;

/* input placeholder style */

::placeholder, ::-ms-input-placeholder, ::-webkit-input-placeholder, ::-moz-placeholder {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
}
  
/* end input aplaceholder style */