
.settingsContainer{
    background-color: #FFFFFF;
}

.subtitle h2 {
    font-size: var(--font-size-lg);
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}
.subtitle p, .generaleSettings__left p, .settingItem__label p, .settingItem__labelSeverity p{
    font-size: var(--font-size-md);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #667085;
}

.generaleSettings{
    display: grid;
  grid-template-columns: 300px auto;
    
    gap: 20px;
    padding-top: 20px;
}

.generaleSettings__left h4{
    font-size: var(--font-size-md);
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
}

.settingItem:not(:last-child){
    padding-bottom: 17px;
    margin-bottom: 17px;
    border-bottom: 1px solid #EAECF0
}

.settingItem__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    
}

.settingItem__label{
    max-width: 80%;
}
.settingItem__labelSeverity{
    max-width: 65%;
}
.settingItem__labelSeverity .slider :global(.ant-slider){
    width: 30% !important;
}

.settingItem__labelSeverity .slider :global(.ant-slider .ant-slider-handle:hover::after){
    box-shadow: 0 0 0 2.5px var(--main-color);
}
.settingItem__labelSeverity .slider :global(.ant-slider .ant-slider-handle)::after{
    width: 50px !important;
    height: 50px !important;
}
.sliderSeverity :global(.ant-slider-horizontal .ant-slider-step){
    top: 20;
}
.settingItem__control{
    padding-top: 10px;
}
.settingItem__label h5, .settingItem__labelSeverity h5{
    font-size: var(--font-size-md);
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
}
.settingItem__control :global(.ant-input-affix-wrapper){
    width: 242px !important;
    padding: 0px 11px !important;
}

.settingItem__control .inputField{
    width: 242px !important;
    height: 44px !important;
    border: 1px solid #D0D5DD;
    border-radius: var(--main-radiuse);
    font-size: var(--font-size-md);
    padding-left: 10px;
}

.control{
    display: flex;
    align-items: center;
}

.control .suffix{
    font-size: var(--font-size-md);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:  #101828;

    height: 44px;
    display: flex;
    align-items: center;
    border: 1px solid #D0D5DD;
    padding-left: 20px;
    padding-right: 10px;
    border-left: none;
    margin-left: -10px;
    border-top-right-radius: var(--main-radiuse);
    border-bottom-right-radius: var(--main-radiuse);
}


/* custom slider style */

.sliderContainer {
    width: 300px;
    position: relative;
  }
  
  .labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: var(--font-size-md);
    color: #666;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    border-radius: 5px;
    outline: none;
    opacity: 1;
    transition: opacity 0.2s;
  }
  
  .slider:hover {
    opacity: 0.8;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #333;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #333;
    cursor: pointer;
  }
  
  .valueIndicator {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    font-size: var(--font-size-md);
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--second-color);
    border: 2px solid var(--main-color);
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--second-color);
    border: 2px solid var(--main-color);
    cursor: pointer;
  }
