.listContainer {
    width: 100%;
    padding: 1rem;
  }
  
  .resourceItem {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: box-shadow 0.2s ease;
  }
  
  .resourceItem:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    font-size: 1.25rem;
    background-color: #A6C4FB;
    border: 4px solid #DCE8FF;
    border-radius: 9999px;
    padding: 6px;
  }
  
  .icon.iconVideo {
    font-size: 1.25rem;
    background-color: #AC8DC3;
    border: 4px solid #D5C5E1;
    border-radius: 9999px;
    padding: 6px;
    color: rgb(255, 0, 0);
  }
  .icon.iconLink {
    font-size: 1.25rem;
    background-color: #AC8DC3;
    border: 4px solid #D5C5E1;
    border-radius: 9999px;
    padding: 6px;
    color: rgb(255,255,255);
  }
  .textContainer {
    display: flex;
    flex-direction: column;
  }
  
  .fileName {
    color: #1F2937;
    font-weight: 500;
  }
  
  .questionNumber {
    color: #6B7280;
    font-size: 0.875rem;
  }


  .iconWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
  }