
.customModal .ant-modal-content{
    background-color: var(--white-color) ;
    border-radius: var(--modal-radius);
}
.customCardContainer{
    display: flex;
    justify-content: center;
    max-width: 438px;
    gap: 16px;
    margin: 0 auto;
    padding-top: 16px;
}
.customCard{
    width: 100%;
    text-align: center;
    padding: 20px;
    box-shadow: 0px 0px 4px 0px #00000040;
}

.customText{
    color: var(--main-color);
    text-align: center;
    font-size: var(--font-size-xl);
    line-height: var(--font-size-xxl);
    font-weight: 500;
    width: 100%;
    margin: 0 auto;
    display: inline-block;
    padding-top: 11px;
    padding-bottom: 45px;
}
.customButton, .customButton:hover{
    display: flex;
    margin: 0 auto;
    width: 163px;
    height: 41px;
    padding: 8px 0;
    border-radius: 9px;
    background-color: var(--main-color) !important;
    color: var(--second-color) !important;
    box-shadow: 0px 0px 11.56px 0px #0000001A;

}

.selected{
    color: var(--second-color);
}

.customCard.selected{
    color: var(--second-color);
    border-color: var(--second-color);
}