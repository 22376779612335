.uploadWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.error  > div, .error > div > div{
  border-color: red;
}
.errorMessage {
  color: red;
  font-size: 12px;
  margin-top: -14px;
}
.wrapper {
  width: 100%;
  border: 1px dashed #D0D5DD;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: #3b82f6;
    background: rgba(59, 130, 246, 0.04);
  }

  &.dragging {
    border-color: #3b82f6;
    background: rgba(59, 130, 246, 0.04);
  }

  .input {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b82f6;
  }

  .title {
    margin: 0;
    font-weight: 500;
    color: #101828;
    >strong{
      font-weight: 600;
    }
  }

  .description {
    margin: 0;
    font-weight: 400;
    color: #475467;
  }
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  background: #FFFFFF;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: #3b82f6;
    background: rgba(59, 130, 246, 0.04);
  }
}




.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background: #ffffff;
  border: 1px solid #EAECF0;
  border-radius: 8px;

  .fileInfo {
    display: flex;
    align-items: start;
    gap: 8px;
  }

  .fileDetails{
    display: flex;
    flex-direction: column;
  }

  .fileName {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    max-width: 90ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .fileSize {
    font-size: 14px;
    font-weight: 400;
    color: #475467;
  }

  .removeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background: none;
    border: none;
    color: #667085;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #EF4444;
    }
  }
}

// Size variants
.sm {
  .content {
    padding: 12px 16px;
  }

  .icon {
    svg {
      width: 36px;
      height: 36px;
    }
  }

  .title {
    font-size: 14px;
  }

  .description {
    font-size: 12px;
  }
}

.md {
  .content {
    padding: 16px 24px;
  }

  .icon {
    svg {
      width: 46px;
      height: 46px;
    }
  }

  .title {
    font-size: 16px;
  }

  .description {
    font-size: 14px;
  }
}

.lg {
  .content {
    padding:  28px 36px;
  }

  .icon {
    svg {
      width: 56px;
      height: 56px;
    }
  }

  .title {
    font-size: 18px;
  }

  .description {
    font-size: 16px;
  }
}
