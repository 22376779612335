.newAssignmentPage{
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 0px 5.6px 0px #0000001A;
    margin: 32px;
}

.newAssignmentContainer{
    padding: 22px 32px;
}

.newAssignmentPage h1 {
    font-family: "Work Sans", sans-serif;
    font-size: var(--font-size-lg);
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}

.newAssignmentContent{
    margin-bottom: 25px;
}

.newAssignmentContent .newAssignmentForm .newAssignmentHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.newAssignmentContent .newAssignmentForm .newAssignmentHeader .newAssignmentTitle > h1 {
    font-family: "Work Sans", sans-serif;
    font-size: var(--font-size-lg);
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #101828;
}

.newAssignmentForm .formField{
    display: flex;
    margin-bottom: 24px;
}

.newAssignmentForm .formField > label, .dueDate label {
    width: 280px;
    font-family: "Work Sans", sans-serif;
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
}
.dueDate{
    display: flex;
    margin-bottom: 24px;
}
.ant-picker{
    width: 512px;
    height: 44px;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 10px 14px;
}
.newAssignmentForm .formField .formFieldInput, .dueDate .ant-picker-range {
    width: 512px;
    height: 44px;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 16px;

}

.rubricBlock{
    display: inline-block;
    margin-top: 6px;
}

.rubricBlock label{
    font-family: "Work Sans", sans-serif;
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
    display: block;
    padding-bottom: 6px;
}

.rubricBlock .rubricBtn, .rubricBlock .rubricBtn:hover{
    display: flex;
    background-color: var(--second-color) !important;
    color: var(--main-color) !important;
    border: none;
    padding: 8px 15px;
    border-radius: var(--main-radiuse);
    cursor: pointer;

}
.magicBlockInput{
    display: flex;
    align-items: center;
    gap: 3px;
}

.magicBlockInput .magicButton{
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
}
.magicBlock{
    display: flex;
    align-items: end;
    gap: 3px;
    position: relative;
    margin-top: 6px;

}

.containerBlockTextAreaAndUpload .magicButton{
    margin-bottom: 7px;
}

.blockTextAreaAndUpload{
    position: relative;
    width: 100%;
}

.uploadFile{
    position: absolute;
    right: 10px;
    bottom: 10px;
    border: none;
}

.blockTextAndUpload {
    position: relative;
    width: 100%;
    height: 44px;
}
.uploadFileInTextInput{
    right: 6px;
    bottom: 6px;
    position: absolute;
    border: none;
}

.magicButton, .magicButton:hover{
    background: linear-gradient(131.76deg, #000095 8.5%, #2E1493 33.96%, #562691 58.73%, #662D91 71.8%) !important;
}

.rubricBlock .rubricSelect{
    height: 44px;
    cursor: pointer;
    min-width: 209px;
}

.newAssignmentForm .formField .formFieldInputSelect {
    width: 512px;
    height: 44px;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
}

.popoverMenu {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 8px 12px;
    width: 300px;
}

.menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-size: 14px;
    color: #333;
}

.popoverButton {
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    height: 40px;
    width: 125px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 5px;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.arrowIcon {
    font-size: 12px;
    margin-left: 4px;
}

.formContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 800px;
    margin: auto;
}

.questionsBlockContent{
    padding: 10px 27px;
}

.questionBlock {
    background-color: #ffffff;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.questionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--main-color);
    border-top-left-radius: var(--main-radiuse);
    border-top-right-radius: var(--main-radiuse);
}

.questionHeader h3{
    margin: 8px;
    background-color: var(--white-color);
    color: #101828;
    padding: 10px 14px;
    border-radius: var(--main-radiuse);
}
.headerBlockBtn{
    display: flex;
    align-items: center;
    gap: 20px;
}

.settingBlock{
    display: flex;
    align-items: center;
    gap: 10px;
}

.removeQuestion {
    background-color: var(--second-color);
    color: var(--main-color);
    border: none;
    padding: 8px 13px;
    border-radius: var(--main-radiuse);
    cursor: pointer;
    margin-right: 25px;
}

.questionTypeBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.questionTypeBlock .selectField{
    border: 1px solid #D0D5DD;
    background-color: #FFFFFF;
    border-radius: var(--main-radiuse);
    min-width: 209px;
}

.selectField{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDEuNUw2IDYuNUwxMSAxLjUiIHN0cm9rZT0iIzY2NzA4NSIgc3Ryb2tlLXdpZHRoPSIxLjY2NjY3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==")
    no-repeat;
    background-size: 12px;
    background-position: calc(100% - 10px) 17px;
    background-repeat: no-repeat;
    font-size: var(--font-size-md);
    color: #101828;
}

.questionTypeBlock label, .questionTypeBlock div label{
    font-family: "Work Sans", sans-serif;
    font-size: var(--font-size-sm);
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #344054;
    padding-bottom: 6px;
}

.questionTypeBlock .selectField{
    height: 44px;
}

.choiceAnswersMode{
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding: 4px;
    display: inline-flex;
    height: 44px;
    width: 214px;
    cursor: pointer;
}

.btnSelectMode{
    background-color: var(--white-color);
    flex: 1;
    padding: 8px;
    border-radius: 8px 0 0 8px;
    border: none;
}

.questionText{
    margin-top: 14px;
}

.contextToggle, .justificationToggle {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    font-family: Arial, sans-serif;
}

.justificationToggle{
    margin-top: 14px;
}

.contextMenuIcon {
    font-size: 20px;
}

.contextLabel {
    width: 88px;
    font-size: 15px;
    color: #333;
}
.textInput{
    height: 44px;
    border-radius: var(--main-radiuse);
    font-size: 16px;
}

.textAreaInput{
    border-radius: var(--main-radiuse);
    height: 100px;
    border: 1px solid #D0D5DD;
    width: 100%;
    padding: 10px 14px;
    font-size: 16px;
    resize: none;
}
.contextInput{
    display: inline-block;
    width: 100%;
    margin: 10px 0 0;
    border-radius: var(--main-radiuse);
    font-size: 16px;
}

.questionText label{
    font-size: 15px;
    color: #333;
    padding-bottom: 6px;
}

.textInput,
.selectField {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #D0D5DD;
    border-radius: var(--main-radiuse);
}

.selectField{
    margin-top: 6px;
}

.optionsContainer {
    margin-top: 15px;
    color: #333;
}
.optionsContainer .textInput{
    height: 44px;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    padding: 10px 14px;
    margin-bottom: 0;

}

.optionIndex{
    width: 40px;
    height: 40px;
    background-color: var(--main-color);
    color: var(--second-color);
    border-radius: 50%;
    padding: 12px;
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center;
}

.blockBtn{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    font-size: var(--font-size-md);
    font-weight: 500;
}

.blockBtn .submitButton {
    display: inline-block;
    margin-top: 10px;
    background-color: var(--main-color);
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: var(--main-radiuse);
    cursor: pointer;
    float: right;
    &:disabled {
        background-color: var(--second-color);
        color: var(--main-color);
    }
}

.blockBtn .draftButton{
    display: inline-block;
    margin-top: 10px;
    background-color: var(--white-color);
    color: var(--main-color);
    border: 1px solid var(--second-color);
    padding: 8px 15px;
    border-radius: var(--main-radiuse);
    cursor: pointer;
    float: right;
}


:global(.ant-switch-checked), :global(.ant-switch-checked):hover{
    background: var(--main-color) !important;
}

.blockUploadFiles{
    background-color: #F9FAFB;
    border: 1px solid #EAECF0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: var(--main-radiuse);
}

.blockUploadFiles .iconFile{
    width: 32px;
    height: 32px;
    background: #A6C4FB;
    border: 4px solid #DCE8FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.fileContent{
    display: flex;
    gap: 4px;
}

.blockUploadFiles .blockFileInfo{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.objectiveBlock{
    display: flex;
    position: relative;
}

.objectiveFileContainer {
    width: 512px;
    position: relative;
}

.objectiveBlock label{
    width: 280px;
}


.uploadFileInObjective{
    right: 6px;
    bottom: 6px;
    position: absolute;
    border: none;
}

.objectiveBlock .textAreaObjective{
    width: 512px;
    height: 200px;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: var(--main-radiuse);
    padding: 10px 14px;
    font-size: 16px;
    resize: none;
}
.uploadFileContextBlock{
    margin-top: 24px;
    display: flex;
}
.uploadFileContextBlock label{
    width: 280px;
}
.uploadFileContextBlock .uploadFileContext :global(.ant-upload) {
    height: 200px;
    width: 512px;
    border-radius: 12px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    border-color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileCloudUpload{
    background-color: #F2F4F7;
    border: 6px solid #F9FAFB;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #475467;
}

.uploadFileContextBlock .uploadFileContext .uploadTextContent{
    width: 300px;
    margin: 0 auto;
}

.fileCloudUpload .iconCloudUpload{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*** temporaire modal  */
.modal {
    text-align: center;
}

.modalContent{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
}

.iconWrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background-color: rgba(102, 51, 204, 0.1); /* Purple background */
    border-radius: 50%;
}

.icon {
    font-size: 24px;
    color: #6633cc; /* Purple color */
}

.title {
    margin-top: 16px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.message {
    margin-top: 8px;
    font-size: 14px;
    color: #555;
}

.footer {
    margin-top: 20px;
    text-align: center;
}

.footer a {
    color: #6633cc; /* Purple color */
    font-weight: bold;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}
/**** end temporaire modal*/