.student-submissions-tabs .ant-tabs-nav-list{
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px; */
    border-top-left-radius: var(--main-radiuse) !important;
    border-top-right-radius: var(--main-radiuse) !important;
    background-color: #ffffff;
}

.student-submissions-tabs .ant-tabs-nav-list .ant-tabs-tab{
    padding: 12px 12px !important;
}
.student-submissions-tabs .ant-tabs-nav-list .ant-tabs-tab:hover{
    color: #000095 !important;
}
.student-submissions-tabs .ant-tabs-nav-list .ant-tabs-tab-btn{
     font-size: var(--font-size-lg) !important;
     font-weight: 600 !important;
     line-height: var(--font-size-lg) !important;
     display: flex;
     align-items: center;
     gap: 5px;
     color: #667085;
}

.student-submissions-tabs .ant-tabs-nav-list .ant-tabs-tab-btn:hover{
    color: #000095 !important;
  }

.student-submissions-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #000095 !important;
}
.student-submissions-tabs .ant-tabs-ink-bar{
    background-color: #000095 !important;
}

.student-submissions-tabs>.ant-tabs-nav::before{
    border-bottom: none !important;
  }
.student-submissions-tabs .ant-tabs-nav-list .ant-tabs-tab span.tab-mode-icon-container {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
  }
  
.student-submissions-tabs .ant-tabs-tab.ant-tabs-tab-active span.tab-mode-icon-container, .student-submissions-tabs .ant-tabs-nav-list .ant-tabs-tab:hover span.tab-mode-icon-container{
    background: linear-gradient(131.76deg, #000095 8.5%, #2E1493 33.96%, #562691 58.73%, #662D91 71.8%);
    color: #ffffff;
  }

.w-max-content{
    min-width: max-content !important;
}

.sub-table{
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #ccc;

}
.sub-table-header-item,.sub-table-row-item{
    display: flex !important;
    border-bottom: 1px solid #ccc;
}
.sub-table-title-item,.sub-table-body-item{
    flex-grow: 1 !important;
    padding: 12px 16px;
    border-right: 1px solid #ccc;
    display: flex !important;
    justify-content: center
}
.sub-table-title-item{
    font-weight: bold;
}
.expandedTable{
    color: #1C1D1E !important;
    background-color: #f7f9fc !important;
}
.expandedTable td{
    background-color: transparent !important;
}
.row-clickable{
    cursor: pointer;
}
.algin-center{
display: flex !important;
align-items: center;
justify-content: center;
}

.algin-center .tableBody__cell-formatCol {
    padding: 8px 12px !important;
}

.action__container{
    display: flex !important;
    flex-direction: row;
    align-items: center;
    gap: 3px;
}

.tableBody__cell-openBtn{
    font-size: var(--font-size-md) !important;
    height: 40px !important;
    min-width: 40px !important;
    display: flex ;
    align-items: center;
    justify-content: center;
}