.miniTableContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .searchContainer {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    gap: 16px;
   .filterBtn{
    height: 40px;
   }
    > div {
      width: fit-content;
    }
  }
  .miniTable {
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    .miniTableHead {
      background: #f9fafb;
      border-bottom: 1px solid #eaecf0;
      .miniTableHeadCell {
        padding: 12px 24px;
        > div {
          text-align: start;
        }
      }
    }
    .miniTableBodyRow {
      border-bottom: 1px solid #eaecf0;
      .miniTableBodyCell {
        padding: 16px 24px;
        .noResults {
          text-align: center;
          padding: 32px 24px;
          font-family: "Inter";
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          color: #475467;
        }
        &.loading {
          > div {
            height: 100px !important;
          }
        }
      }
    }
  }
}
