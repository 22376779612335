.container {
    display: flex;
    align-items: stretch;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    min-height: 400px;
    position: relative;
  }
  
  .content {
    flex: 1;
    padding: 0 40px;
  }

  .blockNavButtons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .navButton {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    color: #374151;
  }

  .fileName{
    font-size: 24px;
    font-weight: 600;
    color: #111827;
  }
  
  .navButton:hover:not(.disabled) {
    background: #f3f4f6;
  }
  
  .navButton.disabled {
    color: #d1d5db;
    cursor: not-allowed;
  }
  
  .title {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    text-align: center;
    margin-bottom: 24px;
  }
  
  .section {
    margin-bottom: 24px;
  }
  
  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 12px;
  }
  
  .text {
    color: #374151;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  
  .list {
    list-style-type: disc;
    padding-left: 20px;
    color: #374151;
  }
  
  .listItem {
    margin-bottom: 8px;
    line-height: 1.5;
  }