h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

.containerCorrectionContent{
    box-shadow: 0px 0px 5px 0px #00000030;
    background: #FFFFFF;
    border-radius: 18px;
    padding: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.header{
    display: flex;
    align-items: center;
    gap: 15px;
    padding-top: 30px;
}

.headerLeft{
    display: flex;
    align-items: center;
    gap: 4px;
    flex: 5;
}

.headerRight{
    display: flex;
    align-items: center;
    flex: 6;
}

.studentInfoPagination{
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0px 1px 4px 0px #00000040;
    background-color: #ffffff;
    border-radius: var(--main-radiuse);
    height: 40px;
}

.studentInfo label{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--font-size-md);
    font-weight: 600;
}

.backButton{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    background: #FFFFFF !important;
    border: 1px solid #EAECF0 !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
}

.title {
    font-weight: 600;
    font-size: var(--font-size-xxl);
    line-height: 130%;
    letter-spacing: -0.4px;
    color: #1c1d1e;
}

.containerAssignment {
    background: #ffffff;
    margin-bottom: 20px;
}
.introductionTitle {
    font-weight: 700;
    font-size: var(--font-size-xl);
    line-height: 20px;
    color: #101828;
    margin-bottom: 8px;
}
.introduction {
    font-weight: 600;
    font-size: var(--font-size-md);
    line-height: 25px;
    color: #475467;
}
.listQuistionsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.questionItemContant {
    padding: 1px;
    border-radius: 8px;
    height: fit-content;
}

.correctionAnswer{
    border: 1px solid #E2E8F0;
    border-radius: var(--main-radiuse);
    background-color: #F8F9FD;
    padding: 4px 13px;
    color: var(--main-color);
    font-size: var(--font-size-md);
    font-weight: 500;
    line-height: 30px;
}

.questionItemContant > .header {
    padding: 8px 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
}
.titleQuistion {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.titleQuistion > h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.4px;
    color: #ffffff;
}

.badgeType {
    font-weight: 400;
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
    color: #344054;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    background: #f2f4f7;
    border-radius: 16px;
}
.header button {
    border: none;
    background: none;
    cursor: pointer;
    color: #344054;
}

.contentMain {
    box-sizing: border-box;
    padding: 16px;
    background: #f8f9fd;
    box-shadow: 0px 2px 8.9px rgba(0, 0, 0, 0.1);
    height: fit-content;
    transition: transform 0.1s ease-in-out;
    transform-origin: top;
    overflow: hidden;
    border-radius: var(--main-radiuse);
}
.hide {
    height: 0;
    opacity: 0;
    transform: scaleY(0); /* Adds a shrinking effect */
    position: absolute;
}

.bodyQuestion {
    display: flex;
    gap: 13px;
    margin-bottom: 14px;
    flex: 1;
}
.bodyQuestion img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    border-radius: var(--main-radiuse);
    overflow: hidden;
    padding: 12px;
    background-color: #ffffff;
}
.textContent {
    font-weight: 600;
    font-size: var(--font-size-md);
    line-height: 25px;
    color: #000000;
}

.blockOptionsItem{
    display: flex;
    gap: 6px;
    align-items: center;   
}

.options {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 42px;
}
.optionChoiceItem{
    display: flex;
    align-items: center;
    gap: 6px;
}
.optionItem {
    width: fit-content;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding-left: 17px;
    padding-right: 17px;
    gap: 6px;

    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.optionItem.selected {
    background: #ffcf00;
    border: 1px solid #000095;
    border-radius: 8px;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    user-select: none;
    padding-right: 17px;
    padding-left: 17px;
    gap: 6px;
    height: 47px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    transition: background-color 0.3s, border-color 0.3s;
}

.checkedOption {
    background: #ffcf00;
    border: 1px solid #000095;
}

.customCheckbox {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    transition: background-color 0.3s, border-color 0.3s;
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    border-radius: 6px;
}

.customCheckbox.checked {
    border: 1px solid #000095;
    background-color: #d4d4fb;
}

.checkmark {
    width: 14px;
    height: 14px;
}

.checkboxLabel {
    font-size: var(--font-size-md);
    color: #000095;
    font-weight: 500;
}
.inputAnswer {
    padding: 13px 17px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-weight: 400;
    font-size: var(--font-size-md);
    line-height: 131%;
    color: #333333;
    width: 100%;
    outline: none;
    border: none;
}
.textareaLabel {
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: 28px;
    color: #101828;
    padding-top: 14px;
}
.textareaV2 {
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: var(--main-radiuse);
    padding: 10px 14px;
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: 130%;
    outline: none;
    border: none;
    resize: none;
    color: #333333;
    &::placeholder{
        color: #667085;
    }
}
.textarea {
    box-sizing: border-box;
    padding: 10px 14px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    transition: all 0.3s ease;
    border-radius: 8px;
    resize: none;
    outline: none;
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: 130%;
    width: 100%;
    color: #333333;
    &::placeholder{
        color: #667085;
    }
}
.justification:focus,
.textareaV2:focus,
.justification:focus-within,
.textareaV2:focus-within {
    border: 1px solid #000095;
}
.textareaContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.blockBtns{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.btnSubmitContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 500px;
    width: 500px;
    gap: 8px;
}
.btnSubmit {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    padding: 10px 16px;
    background: #000095;
    border: 1px solid #000095;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: var(--main-radiuse);
    font-weight: 600;
    font-size: var(--font-size-md);
    line-height: 130%;
    color: #ffffff;
    cursor: pointer;
}
.btnDownload{
    border: none;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    padding: 10px 16px;
    cursor: pointer;
    color: var(--main-color);
    font-weight: 600;
    font-size: var(--font-size-md);
    line-height: 130%;
    border: 1px solid var(--main-color) !important;
    border-radius: var(--main-radiuse);
    &:disabled {
        cursor: not-allowed !important;
    }
}

.questionItemContainer {
    display: grid;
    grid-template-columns: 1fr 500px;
    gap: 20px;
}

.correctionsContainer {
    box-sizing: border-box;
    background: #f8f9fd;
    border: 1px solid #ffcf00;
    border-top: 22px solid #ffcf00;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.correctionbody{
    padding: 11px 12px;
}

.correctionsTitle {
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: 130%;
    color: #0c111d;
}
.rubricName{
    color: #000095;
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: 130%;
}
.cercleAnwser {
    height: 40px;
    width: 40px;

    border-radius: 50%;
    background-color: #ffcf00;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cercleAnwser.cercleAnwserInCorrect {
    background-color: #0000952b;
}
.optionsContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.correctionsContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.justificationContent {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.justificationTitle > span:first-child {
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: 130%;
    color: #0c111d;
    padding-right: 8px;
}

.justificationNumber {
    font-weight: 700;
    font-size: var(--font-size-md);
    line-height: 130%;
    color: #000095;
}
.justificationAnswerContainer p {
    box-sizing: border-box;
    padding: 6px 14px;
    background: #f8f9fd;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    min-height: 90px;
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: 20px;
    color: #000095;
}

.barContainer {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}
.scoreBar {
    flex-grow: 1;
    background: rgba(0, 0, 149, 0.17);
    border-radius: 20.7522px;
    height: 20px;
    cursor: pointer;
}

.scoreBar.active {
    background: #000095;
}

.feedbackContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    border: 1px solid #E2E8F0;
    border-radius: var(--main-radiuse);
    padding: 10px;
}

.feedbackContent{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
}

.feedbackHeader{
    display: flex;
    justify-content: space-between;
    align-items: center
}

.feedbackHeader h3{
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);
    line-height: var(--font-size-lg);
    color: #0C111D;
}

.feedbackHeader .ant-btn.ant-btn-default{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--secondary-color) !important;
    border-radius: 6px;
    color: var(--secondary-color);
}

.feedback{
    box-sizing: border-box;
    padding: 6px 14px;
    background: #f8f9fd;
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: var(--main-radiuse);
    min-height: 90px;
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: 130%;
    color: var(--main-color);
    width: 100%;
    border-left: 5px solid var(--main-color);
}

.feedbackItem{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
    cursor: pointer;
}

.feedback:hover{
    background-color: rgba(0, 0, 149, 0.17);
}


/* style pour les ressources */
.resourcesConteiner{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 14px;
}

.resourcesConteiner h6{
    font-size: var(--font-size-md);
    font-weight: 600;
    color: #344054;
    padding-top: 20px;
}

.resourceItemContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
}

.resourceItem, .resourceItem:hover{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    border: 1px solid #EAECF0;
    background: #ffffff;
    border-radius: var(--main-radiuse);
    padding: 6px 14px;
    cursor: pointer;
    color: #344054;
    height: 51px;
}

.resourceItem :global(.anticon.anticon-file){
    background-color: #A6C4FB;
    border: 4px solid #DCE8FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.resourceItem :global(.anticon.anticon-global){
    background-color: #AC8DC3;
    border: 4px solid #D5C5E1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    color: #ffffff;
}

.resourceItem :global(.anticon.anticon-youtube){
    background-color: #AC8DC3;
    border: 4px solid #D5C5E1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

.videoContainer{
    background-color: #F9FAFB;
    box-shadow: 0px 1px 6.8px 0px #00000040;
    border-radius: var(--main-radiuse);

    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 270px; /* Adjust as needed */
    overflow: hidden;
    position: relative;
    flex: 1 0 50%;
}

.videoContainer iframe{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(312.86deg, rgba(255, 207, 0, 0.63) 17.63%, rgba(255, 216, 115, 0.63) 40.19%, rgba(56, 64, 255, 0.63) 77.73%, rgba(0, 0, 149, 0.63) 104.04%);
    border-radius: var(--main-radiuse);
    margin: 12px;
    height: 92%;
}

.videoContainer img {
    width: 100%;
    display: block; /* Prevents unwanted space below image */
}
  
.playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem; /* Adjust size if necessary */
    color: white; /* Adjust based on your design */
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}
  
.playIcon:hover {
    transform: translate(-50%, -50%) scale(1.1);
}


.resourceColomn{
    display: flex;
    flex-direction: column
}

.correctionHeader{
    height: 22px;
    border-top-left-radius: var(--main-radiuse);
    border-top-right-radius: var(--main-radiuse);
}

.resourceItem span:last-child {
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.headerCorrection{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.totalGrade{
    font-size: var(--font-size-md);
    font-weight: 600;
    white-space: nowrap;
}
.totalGradeValue{
    background-color: var(--main-color);
    color: var(--white-color);
    padding: 0.2rem 0.5rem;
    border-radius: var(--main-radiuse);
}

.showMoreButton{
    border: 1px solid #E0E7FF;
    background-color: #EEF2FF;
    color: var(--main-color);
}
.showMoreButton svg{
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg); /*opera*/
    transform: rotate(180deg); /*likely future standard*/
    color: var(--main-color);
}

.actionsBtns{
    display: flex;
    align-items: center;
    gap: 8px;
}
.rubricButton{
    border: 1px solid #E0E7FF !important;
    background-color: #EEF2FF !important;
    color: var(--main-color) !important;
    padding: 5px 10px !important;
    border-radius: var(--main-radiuse) !important;
}

.context{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: var(--main-color);
}

.contextContent{
    font-weight: 500;
    font-size: var(--font-size-sm);
    line-height: 20px;
    color: #475467;
}

.contextTitle{
    font-weight: 600;
    font-size: var(--font-size-lg);
    line-height: 28px;
    color: #101828;
}

.textContent{
    font-weight: 500;
    font-size: var(--font-size-md);
    line-height: 25px;
    color: #000000;
}

.textContainer{
    flex: 0 1 50%;
}