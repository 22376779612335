.chatContainer {
  width: 100%;
  height: calc(100vh - 98px);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  margin: 0 auto;
}

.chatHeader {
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  text-align: center;

  h2 {
    margin: 0;
    font-size: 1.2rem;
  }
}

.messageContainer {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #f8f9fa;
}

.message {
  max-width: 80%;
  padding: 10px 14px;
  border-radius: 6px;
  word-break: break-word;
  line-height: 1.4;
  position: relative;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  
  &.user {
    align-self: flex-end;
    background-color: #d4d4fb;
    color: #000095;
    border-bottom-right-radius: 4px;
  }

  &.bot {
    align-self: flex-start;
    background: transparent;
    color: #662d91;
    border-bottom-left-radius: 4px;
    
    .messageContent {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      
      > span {
        display: inline-block;
        position: relative;
        white-space: pre-wrap;
        width: 100%;
        min-height: 1.2em;
      }
      
      svg {
        margin-top: 0.2em;
      }
    }
    
    .messageActions {
      display: flex;
      justify-content: flex-end;
      margin-top: 8px;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
    
    &:hover .messageActions {
      opacity: 1;
    }
  }
  
  &.typing {
    .cursor {
      display: inline-block;
      width: 2px;
      height: 17px;
      background-color: #662d91;
      vertical-align: text-bottom;
      margin-left: 1px;
      animation: blink-caret 0.75s step-end infinite;
    }
  }

  &.loading {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    min-width: 60px;
    justify-content: center;
  }
}

.copyButton {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: rgba(102, 45, 145, 0.05);
  color: #662d91;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: rgba(102, 45, 145, 0.15);
  }
  
  svg {
    opacity: 0.8;
  }
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #662d91;
  border-radius: 50%;
  margin: 0 2px;
  animation: bounce 1.4s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

@keyframes blink-caret {
  from, to { opacity: 0; }
  50% { opacity: 1; }
}

.inputArea {
  display: flex;
  padding: 12px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
}

.textInput {
  flex: 1;
  padding: 8px 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;
  font-size: 0.95rem;

  &:focus {
    border-color: #2980b9;
  }
}

.sendButton {
  background-color: var(--main-color);
  color: white;
  border: none;
  border-radius: 6px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #2980b9;
  }

  &:disabled {
    background: #f1f5f9;
    color: #64748B;
    cursor: not-allowed;
  }
}