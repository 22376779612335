.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.controls {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.zoomControls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.zoomButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.zoomButton:hover:not(:disabled) {
  background-color: #e9ecef;
}

.zoomButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.zoomLevel {
  font-size: 14px;
  color: #495057;
  min-width: 60px;
  text-align: center;
  padding: 4px 8px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.viewer {
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.canvas {
  box-shadow: none;
  background-color: transparent;
}
