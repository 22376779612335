
.fractionInput{
    display: block;
    float: right;
    background-color: #FFFFFF;
    border-radius: var(--main-radiuse);
    box-shadow: 0px 1px 4px 0px #00000040;
    max-width: 200px;
    text-align: center;
    padding: 0px 6px;
}