*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

/* Set default font settings */
html {
  font-size: 16px;
  line-height: 1.5;
  scroll-behavior: smooth;
}
* {
  font-family: "Inter", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #333;
  line-height: 1.6;
}

/* Remove list styles */
ul,
ol {
  list-style: none;
}

/* Reset link styles */
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/* Remove default styles from tables */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove default form element styles */
input,
button,
textarea,
select {
  font: inherit;
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
}

/* Button reset */
button {
  cursor: pointer;
  background: transparent;
  color: inherit;
}

/* Image reset */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

p {
  overflow-wrap: break-word;
}
