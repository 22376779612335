
@media (min-width : 1366px) {
    .mainClasses {
        background-position: 110px -85px;
    }
}

@media (min-width : 1440px) {
    .mainClasses {
        background-position: 125px -85px;
    }
}

@media (min-width : 1680px) {
    .mainClasses {
        background-position: 80px -100px;
    }
}

@media (min-width : 1920px) {
    .mainClasses {
        background-position: 80px -130px;
    }
}

.mainClasses {
    padding: 10px 20px 10px 0px;
    height: calc(100vh - 49px);
    display: flex;
    flex-direction: column;
    flex: 1;
}

.classesContent__header {
    display: flex;
    justify-content: space-between;
    padding-top: 2.5rem;
    margin-bottom: 1rem;
}

.classesContent__header-title,
.classesContent__header-new {
    display: flex;
    align-items: center;
    gap: 10px;
}

.classesContent__header-title h1 {
    font-size: 24px;
    margin-left: 9px;
    line-height: 24px;
    font-weight: 600;
}

.classContainer__content{
    padding-top: 20px;
    padding-right: 51px;
}

.classContainer__content h2{
    font-size: var(--font-size-xl);
    line-height: 20px;
    font-weight: 600;
    padding-bottom: 12px;
    letter-spacing: 0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.classContainer__content .classContainer__key, .classContainer__content .classContainer__value{
    font-size: var(--font-size-sm);
    line-height: 18.2px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.classesContent__header-new .search-bar{
    width: 17rem;
    height: 40px;
}

.classesContent__Bottom{
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

@media (min-width : 1177px) {
    .classesContent__Bottom{
        grid-template-columns: repeat(3,1fr);
    }
}

@media (min-width : 1680px) {
    .classesContent__Bottom{
        grid-template-columns: repeat(4,1fr);
    }
}

.createClassModal__header{
    margin-bottom: 8.55px;
}

.createClassModal__header h1{
    font-size: 19.47px;
    line-height: 22.7px;
    margin-bottom: 4px;
}

.createClassModal__header h2{
    font-size: 11px;
    line-height: 130%;
    color: #979797;
}

.createClassModal__bottom{
    display: flex;
    flex-direction: column;
}

.createClassModal__bottom-classTitle label{
    font-family: Work Sans, sans-serif;
    font-size: 11.79px;
    font-weight: 600;
    line-height: 15.33px;
    letter-spacing: -0.3368632197380066px;
    text-align: left;
}


.createClassModal__bottom-SubjectGrade{
    display: flex;
    gap: 14px;
    margin-top: 25px;
}

.createClassModal__bottom-SubjectGrade-subject{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.createClassModal__bottom-SubjectGrade-grade{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.createClassModal__bottom-SubjectGrade-subject label,
.createClassModal__bottom-SubjectGrade-grade label{
    display: flex;
    flex-direction: column;
    margin-bottom: 3.37px;
}

.createClassModal__bottom-SubjectGrade-subject label span:first-child,
.createClassModal__bottom-SubjectGrade-grade label span:first-child{
    font-size: 11.79px;
    font-weight: 600;
    line-height: 15.33px;
    letter-spacing: -0.3368632197380066px;
    text-align: left;
    margin-bottom: 3.77px;
}

.createClassModal__bottom-SubjectGrade-subject label span:last-child,
.createClassModal__bottom-SubjectGrade-grade label span:last-child{
    font-size: 11px;
    font-weight: 600;
    line-height: 14.3px;
    letter-spacing: -0.3368632197380066px;
    text-align: left;
    color: #979797;
}

.createClassBtn{
    height: 34px;
    width: 177px;
    padding: 9.26px 13.47px;
    gap: 13.47px;
    border-radius: 6.74px;
    background-color: #000095;
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    line-height: 14.23px;
    letter-spacing: -0.3368632197380066px;
    text-align: center;
    align-self: center;
    margin-top: 35px;
    &:hover{
        background-color: #26267c !important;
        cursor: pointer;
    }
}

.createClassBtn:hover{
    background-color: #36367c !important;
    color: #fff !important;
}