
.classContainer{
    background: linear-gradient(180deg, #7272FF 0%, #0505A0 0.01%, #000095 0.02%, #7272FF 100%);
    color: #fff;
    border-radius: 8px;
    padding-top: 0;
    padding-left: 23px;
    position: relative;
    overflow: hidden;
    z-index: 0 !important;
    transition: all 0.3s;
}

.classContainer:hover{
    color: #fff;
    box-shadow: 0px 0px 14px 0px #0000001A;
    transition: all 0.3s;
}

.classContainer h1{
    font-family: Inter;
    font-size: var(--font-size-md);
    margin-bottom: 14px;
}

.classContainer__content > div:not(:last-child){
    margin-bottom: 14px;
    &:hover{
       cursor: pointer;
    }
}

.classContainer__content > div > span{
    font-size: var(--font-size-md);
    line-height: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    letter-spacing: 0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.classContainer__value{
    color: #FDD832;
}

.classContainer .classVectorBg{
    position: absolute;
    top: 0;
    right: 0;
}

.classContainer .classLessonIcon{
    position: absolute;
    top: 18px;
    right: 24px;
}

.classContainer__content{
    z-index: 1000 !important;
    position: relative !important;
    color: #fff !important;
}

.classContainer .AddStudentBtn{
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-md);
    height: 40px;
    color: #000095;
    background-color: #FDD832;
    cursor: pointer;
    padding: 9px 15px;
}

.classContainer .AddStudentBtn:hover{
    color: #02027c !important;
    background-color: #efc50e !important;
}

.classContainer .action{
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
}

.action .moreIcon{
    font-size: 28px;
    color: #FDD832;
}

.action .moreIcon svg{
    height: 1.5rem;
    width: 1.5rem;
}

.classDropDownItems{
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 130px;
    height: 100%;
    padding: 6.5px 12px 6.5px 0px;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: var(--font-size-md);
    font-size: var(--font-size-md);
}

.block__btn{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    position: relative;
    z-index: 99999;
    margin-right: 18px;
    width: 10%;
    text-align: right;
    float: right;
}